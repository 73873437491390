@import url('https://fonts.googleapis.com/css?family=Public+Sans');

.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-footer {
  background-color: #324FBE;
}

.title {
  color: var(--light-typography-color-heading-text, #4B465C);

  /* Light/Basic Typography/Display 3 */
  font-size: 48px;
  font-family: 'Public Sans';
  font-weight: 500;
  line-height: 68px;
  display: flex;
  padding: 0px 20px;
}

.description {
  color: var(--light-typography-color-body-text, #4B465C);

  /* Light/Basic Typography/Paragraph Lead */
  font-size: 18px;
  font-family: 'Public Sans';
  line-height: 28px;
  display: flex;
  padding: 0px 20px;
}

.notify-button {
  opacity: 0.65;
  background: #28C76F;
}

.active {
  opacity: 1;
}

.success, .error {
  padding: 10px 15px;
  align-items: flex-start;

  border-radius: 6px;
  border: 1px solid rgba(40, 199, 111, 0.16);
  background: #28B165;
}

.error {
  border: 1px solid rgba(199, 40, 40, 0.16);
  background: rgb(204, 71, 71);
}

.success span, .error span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;

  color: #fff;
  text-align: center;

  /* Light/Basic Typography/Paragraph Medium */
  font-size: 14px;
  font-family: Public Sans;
  font-weight: 500;
  line-height: 22px;
}

.error span {
  color: #fff;
}

.d-none {
  display: none
}